.SearchBox {
    border: none;
    border-left: 2px solid #c8d0d5;
    background-image: url(./search.svg);
    height: 30px;
    background-repeat: no-repeat;
    width: 0px;
    padding-left: 5px;
    padding-right: 25px;
    background-position: right;
    background-size: 25px;
}

.SearchBox:focus {
    width: 120px;
    margin-right: -120px;
    border-bottom: 1px dotted;
    height: 29px;
}

@media screen and (max-width: 800px) {
    .SearchBox {
        width: 240px;
        border-left: none;
        border-bottom: 1px dotted;
        height: 29px;
    }

    .SearchBox:focus {
        margin-right: 0px;
        width: 240px;
    }
    
}