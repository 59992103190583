.pagePadding {
    padding-right: 12%;
    padding-left: 12%;
}
.pagePadding-Investor {
    padding-right: 10%;
    padding-left: 10%;
}

.pageMargin {
    margin-right: 12%;
    margin-left: 12%;
}

.paddingXL {
    padding: 80px;
}
.paddingLL {
    padding: 60px;
}

.paddingL {
    padding: 40px;
}

.paddingM {
    padding: 20px;
}

.paddingS {
    padding: 10px;
}

.paddingTopXXL {
    padding-top: 120px;
}
.paddingTop100 {
    padding-top: 100px;
}

.paddingTopXL {
    padding-top: 80px;
}
.paddingTopLLL {
    padding-top: 60px;
}
.paddingTopLL {
    padding-top: 50px;
}

.paddingTopL {
    padding-top: 40px;
}
.paddingTopML {
    padding-top: 30px;
}
.paddingTopM {
    padding-top: 20px;
}
.paddingTopSM {
    padding-top: 14px;
}

.paddingTopS {
    padding-top: 10px;
}
.paddingBottomXXL {
    padding-bottom: 120px;
}

.paddingBottomXL {
    padding-bottom: 80px;
}
.paddingBottomLL {
    padding-bottom: 60px;
}
.paddingBottomL {
    padding-bottom: 40px;
}
.paddingBottomML {
    padding-bottom: 30px;
}
.paddingBottomM {
    padding-bottom: 20px;
}

.paddingBottomS {
    padding-bottom: 10px;
}

.paddingBottom200 {
    padding-bottom: 200px;
}
.paddingLeftS {
    padding-left: 10px;
}

.paddingLeftXS {
    padding-left: 5px;
}

.paddingLeftM {
    padding-left: 20px;
}

.paddingLeftML {
    padding-left: 30px;
}

.paddingLeftL {
    padding-left: 40px;
}

.paddingLeftXL {
    padding-left: 80px;
}

.paddingLeftXXL {
    padding-left: 160px;
}

.paddingRightXS {
    padding-right: 5px;
}

.paddingRightS {
    padding-right: 10px;
}

.paddingRightM {
    padding-right: 20px;
}
.paddingRightML {
    padding-right: 30px;
}

.paddingRightL {
    padding-right: 40px;
}

.paddingRightXL {
    padding-right: 80px;
}

.paddingRightXXL {
    padding-right: 160px;
}

.TextAlignCenter {
    text-align: center;
}

.TextAlignJustify {
    text-align: justify;
}

@media screen and (max-width: 800px) {
    .pagePadding {
        padding-right: 20px;
        padding-left: 20px;
    }

    .paddingXL {
        padding: 60px;
    }

    .paddingL {
        padding: 30px;
    }

    .paddingM {
        padding: 15px;
    }

    .paddingS {
        padding: 10px;
    }

    .paddingTopXL {
        padding-top: 60px;
    }
    .paddingTopXXL {
        padding-top: 60px;
    }

    .paddingTopL {
        padding-top: 30px;
    }

    .paddingTopM {
        padding-top: 15px;
    }

    .paddingTopS {
        padding-top: 10px;
    }

    .paddingBottomXL {
        padding-bottom: 60px;
    }

    .paddingBottomL {
        padding-bottom: 30px;
    }

    .paddingBottomM {
        padding-bottom: 15px;
    }

    .paddingBottomS {
        padding-bottom: 10px;
    }

    .paddingLeftS {
        padding-left: 10px;
    }

    .paddingLeftXS {
        padding-left: 5px;
    }

    .paddingLeftM {
        padding-left: 15px;
    }
    .paddingTopLLL {
        padding-top: 40px;
    }
    .paddingTopLL {
        padding-top: 35px;
    }
    .paddingLeftL {
        padding-left: 30px;
    }

    .paddingLeftXL {
        padding-left: 60px;
    }

    .paddingLeftXXL {
        padding-left: 120px;
    }

    .paddingRightXS {
        padding-right: 5px;
    }

    .paddingRightS {
        padding-right: 10px;
    }

    .paddingRightM {
        padding-right: 15px;
    }

    .paddingRightL {
        padding-right: 30px;
    }

    .paddingRightXL {
        padding-right: 60px;
    }

    .paddingRightXXL {
        padding-right: 120px;
    }
}
