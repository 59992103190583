.BodySection {
    background-image: none;
}

.BodySection .smallImage {
    max-width: 398px;
    width: 398px;
    height: 311px;
}
.BodySection .contentSection {
    margin-left: 60px;
}
.BodySection .topGridSection {
    height: 579px;
}
.InvestorOurStory .BodySection .topSection {
    width: 100%;
    margin: 0px auto;
}
.InvestorOurStory .BodySection .bottomSection {
    max-width: 700px;
}
.InvestorOurStory .BodySection .timelineContent {
    max-width: 2000px;
    margin: 0px auto;
}
.BodySection .innerContent {
    max-width: 496px;
}
.BodySection .timelineLeftSection {
    padding-left: 160px;
    width: 323px;
}

.BodySection .timelineLeftSectionImage {
    height: 523px;
    width: 419px;

    object-fit: cover;
}

@media screen and (min-width: 2000px) {
    .InvestorOurStory .BodySection .topSection {
        min-width: 1000px;
    }
    .BodySection .innerContent {
        min-width: 1000px;
    }
}

@media screen and (min-width: 2400px) {
    .InvestorOurStory .BodySection .topSection {
        min-width: 1400px;
    }
    .BodySection .innerContent {
        min-width: 1400px;
    }
}
@media screen and (max-width: 1400px) {
    .InvestorOurStory .BodySection .topSection {
        min-width: 400px;
        max-width: 400px;
        padding-right: 60px;
    }
    .InvestorOurStory .BodySection .innerContent {
        min-width: 400px;
        max-width: 400px;
    }
}
@media screen and (max-width: 1200px) {
    .InvestorOurStory .BodySection .MuiGrid-item img {
        width: 500px;
        height: 100%;
        object-fit: cover;
    }
}

@media screen and (max-width: 1000px) {
    .InvestorOurStory .BodySection .topSection {
        min-width: 90%;
        max-width: 90%;
        padding-right: 0px;
    }
    .InvestorOurStory .BodySection .innerContent {
        min-width: 100%;
        max-width: 100%;
    }
    .InvestorOurStory .BodySection .MuiGrid-item {
        width: 100%;
    }
    .InvestorOurStory .BodySection .MuiGrid-item img {
        width: 100%;
        height: 400px;
        object-fit: cover;
    }
    .InvestorOurStory .BodySection .timelineLeftSection {
        width: 100%;
    }

    .InvestorOurStory .BodySection .timelineLeftSectionImage {
        width: 300px !important;
        height: 400px !important;
        padding-bottom: 60px !important;
    }
    .InvestorOurStory .BodySection .topGridSection {
        height: auto !important;
    }
    .BodySection {
        background-repeat: no-repeat;
        background-position: left 100%;
        background-size: 50% auto;
        padding-bottom: 100px;
    }

    .BodySection .ourStory {
        min-height: 150px;
        margin-top: 0px;
    }

    .BodySection .carotsImage {
        max-width: 100%;
    }

    .InvestorOurStory .BodySection .marginOnMobile {
        padding: 0px !important;
        margin: 20px auto;
        width: 90%;
    }
    .InvestorOurStory .BodySection .topGridSection {
        height: auuto;
    }

    .InvestorOurStory .timelineLeftSection {
        padding-left: 20px;
    }

    .InvestorOurStory .MuiGrid-grid-xs-4,
    .InvestorOurStory .MuiGrid-grid-xs-6,
    .InvestorOurStory .MuiGrid-grid-xs-8 {
        max-width: 100% !important;
        flex-basis: 100%;
        width: 100%;
        padding-left: 0px;
    }
    .InvestorOurStory .MuiGrid-container {
        width: 90%;
        margin-left: 5%;
    }
}

@media screen and (max-width: 800px) {
    .InvestorOurStory .BodySection .MuiGrid-item img {
        width: 100%;
        height: 300px;
        object-fit: cover;
    }
    .InvestorOurStory .timelineLeftSection {
        padding-left: 0;
    }
    .InvestorOurStory .BodySection .timelineLeftSectionImage {
        width: 100% !important;
        height: 250px;
        margin-left: 0 !important;
        padding-bottom: 0px;
        object-fit: contain;
    }
}
