.BodySection {
    background-image: none;
}

.InvestorWhyUs .BodySection .fistSection {
    min-height: 647px;
    background-image: url(./background.png);
}

.InvestorWhyUs .BodySection .buttonRow {
    font-size: 14px;
    text-decoration: underline;
    margin-top: 0px;
}
.InvestorWhyUs .BodySection .textContent {
    max-width: 750px;
    margin: 0px auto;
}

.InvestorWhyUs .BodySection .innerContent {
    min-width: 90%;
}
.InvestorWhyUs .BodySection .LogoImage {
    width: 79px;
    height: 116px;
    margin: 20px auto;
}

@media screen and (min-width: 1800px) {
    .InvestorWhyUs .BodySection .textContent {
        max-width: 80%;
    }
}
@media screen and (max-width: 1400px) {
    .InvestorWhyUs .BodySection .secondSection.pagePadding-Investor {
        padding-left: 60px;
        padding-right: 60px;
    }
}
@media screen and (max-width: 1230px) {
    .InvestorWhyUs .BodySection .secondSection.pagePadding-Investor {
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media screen and (max-width: 1150px) {
    .InvestorWhyUs .BodySection .MuiGrid-grid-xs-4 {
        max-width: 100% !important;
        flex-basis: 100%;
        width: 100%;
        padding-left: 0px;
    }
    .InvestorWhyUs .BodySection .MuiGrid-grid-xs-8 {
        max-width: 100% !important;
        flex-basis: 80%;
        width: 100%;
        padding-left: 0px;
    }
    .InvestorWhyUs .BodySection .secondSection button {
        margin-bottom: 20px;
    }
}
@media screen and (max-width: 1150px) {
    .InvestorWhyUs .BodySection .MuiGrid-grid-xs-8 {
        max-width: 100% !important;
        flex-basis: 100%;
        width: 100%;
        padding-left: 0px;
    }
}
@media screen and (max-width: 800px) {
    .InvestorWhyUs .BodySection .MuiGrid-grid-xs-4,
    .InvestorWhyUs .BodySection .MuiGrid-grid-xs-6,
    .InvestorWhyUs .BodySection .MuiGrid-grid-xs-8 {
        max-width: 100% !important;
        flex-basis: 100%;
        width: 100%;
        padding-left: 0px;
    }
}
