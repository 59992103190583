.CardsSection {
    position: relative;
    padding-top: 50px;
    padding-bottom: 80px;
    background-image: url(./background.png);
}

.CardsSection .owl-carousel {
    padding-bottom: 120px;
}

.header {
    text-align: center;
    color: white;
    margin-bottom: 40px;
}
@media screen and (max-width: 1300px) {
    .CardsSection {
        position: relative;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .CardsSection.pagePadding {
        padding-right: 10px;
        padding-left: 10px;
    }

    .CardsSection .owl-carousel {
        padding-bottom: 60px;
    }

    .Home .CardsSection .header {
        margin-bottom: 0px;
    }
    .Home .CardsSection .MuiGrid-container {
        justify-content: center;
    }
    .Home .CardsSection .MuiGrid-grid-xs-7 {
        max-width: 50% !important;
        flex-basis: 50%;
        width: 50%;
        padding-left: 0px;
    }
    .Home .CardsSection .MuiGrid-justify-xs-flex-end {
        margin-left: 80px;
    }
}

@media screen and (max-width: 800px) {
    .CardsSection {
        position: relative;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .CardsSection.pagePadding {
        padding-right: 10px;
        padding-left: 10px;
    }

    .CardsSection .owl-carousel {
        padding-bottom: 60px;
    }

    .Home .CardsSection .MuiGrid-grid-xs-7 {
        max-width: 100% !important;
        flex-basis: 100%;
        width: 100%;
        padding-left: 0px;
    }

    .Home .CardsSection .MuiGrid-grid-xs-5 {
        max-width: 90% !important;
        flex-basis: 90%;
        width: 90%;
        padding-left: 0px;
    }
    .Home .CardsSection .header {
        margin-bottom: 0px;
    }
}
