.TextField {
    text-align: left;
    position: relative;
    height: 70px;
}

.TextField.error {
    color: red;
}

.TextField.textarea {
    height: unset;
}

.TextField .textFieldLabel {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    font-weight: bold;
}

.TextField.box .textFieldLabel {
    font-weight: normal
}

.TextField.textarea .textFieldLabel {
    position: relative;
    display: block;
}

.TextField input,
.TextField textarea {
    outline: none;
}

.TextField input {
    border-top: none;
    border-right: none;
    border-left: none;
    background: transparent;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 40px;
    width: 100%;

    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.TextField.error input {
    border-color: red;
    border-width: 2px;
}

.TextField.box input {
    top: 20px;
    padding-top: 0px;
    padding-left: 10px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    width: calc(100% - 12px);
}


.TextField textarea {
    resize: none;
    height: 150px;
    margin-top: 20px;
    padding: 10px;
    width: calc(100% - 20px);
    border-width: 1px;
    border-style: solid;
}

@media screen and (max-width: 800px) { 
    .TextField {
        height: 60px;
    }
    
    .TextField .textFieldLabel {
    }

    .TextField textarea {
        height: 100px;
    }
    

}