.MortgageSection {
    margin: 0px -20px;
}

.MortgageSection .rowItem {
    flex: 1;
}

@media screen and (max-width: 800px) { 
    .MortgageSection {
        margin: 0px;
    }
    
}