* {
    transition: all 150ms ease-in-out;
}

html{
    overflow-x: hidden;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    max-width: 100%;
    margin: 0px auto;
    font-weight: lighter;
    background-color: #fff;
    overflow-x: hidden;
}

a {
    text-decoration: none;
    color: inherit;
}

.withBackground {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

button, input {
    outline: none;
}

.row {
    display: flex;
}

.col1 {
    flex: 1;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.centerText {
    text-align: center;
}

.leftText {
    text-align: left;
}

.rightText {
    text-align: right;
}

.bold {
    font-weight: bold;
}

.boldish {
    font-weight: normal;
}

.roundedBig {
    border-radius: 15px;
}

.roundedBottomBig {
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
}

.roundedRightBig {
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
}

.roundedLeftBig {
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
}

.roundedTopBig {
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}

.roundedTopSmall {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.roundedBottomSmall {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.roundedSmall {
    border-radius: 5px;
}

.arrow {
    width: 0;
    height: 0;
    border-left: 7.5px solid transparent;
    border-right: 7.5px solid transparent;
    border-top: 7.5px solid;
    margin-left: 5px;
}

.hidden {
    display: none !important;
}

@media screen and (max-width: 800px) { 
    .row {
        flex-direction: column;
    }
}