.ResourcesSection {
    height: 450px;
    background-image: url(./background.png);
    display: flex;
    flex-direction: column;
    padding-left: 225px;
}

.ResourcesSection .plainText {
    max-width: 360px;
    margin-left: 0xp;
}

@media screen and (max-width: 1250px) {
    .ResourcesSection {
        padding-left: 60px;
    }
}
@media screen and (max-width: 800px) {
    .ResourcesSection {
        height: 250px;
        padding-left: 40px;
    }
    .ResourcesSection .plainText {
        width: 150px;
    }
}
@media screen and (max-width: 500px) {
    .ResourcesSection {
        height: 250px;
        padding-left: 20px;
    }
    .ResourcesSection .plainText {
        width: 150px;
    }
}
@media screen and (max-width: 400px) {
    .ResourcesSection {
        height: 300px;
        padding-left: 10px;
    }
    .ResourcesSection .plainText {
        width: 100px;
    }
}
