body {
    font-size: 16px;
}
.fontSizeXXL {
    font-size: 66px !important;
}
.fontSizeXL {
    font-size: 55px;
}
.fontSizeLL {
    font-size: 50px;
}
.fontSize40 {
    font-size: 40px !important;
}
.fontSizeL {
    font-size: 35px;
}
.fontSize28 {
    font-size: 28px !important;
}
.fontSize26 {
    font-size: 26px;
}
.fontSizeMM {
    font-size: 21px !important;
}
.fontSizeM {
    font-size: 18px;
}

.fontSizeS {
    font-size: 16px;
}

.fontSizeXS {
    font-size: 14px;
}

.fontSizeXXS {
    font-size: 12px;
}

.lineHeightSM {
    line-height: 1.3;
}
.lineHeightM {
    line-height: 1.5;
}
.fontWeightNormal {
    font-weight: normal;
}
.fontWeight300 {
    font-weight: 300;
}
.fontWeightBold {
    font-weight: bold;
}

.underline {
    text-decoration: underline;
}

@media screen and (max-width: 800px) {
    .fontSizeXXL {
        font-size: 40px !important;
    }
    .fontSizeXL {
        font-size: 35px;
    }

    .fontSizeLL {
        font-size: 30px !important;
    }
    .fontSize40 {
        font-size: 27px !important;
    }
    .fontSizeL {
        font-size: 24px;
    }

    .fontSize26 {
        font-size: 19px;
    }
    .fontSizeM {
        font-size: 16px;
    }

    .fontSizeS {
        font-size: 14px;
    }

    .fontSizeXS {
        font-size: 12px;
    }

    .fontSizeXXS {
        font-size: 8px;
    }
}
