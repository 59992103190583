.ProductCard {
    min-width: 280px;
    max-width: 350px;
}

.ProductCard .row {
    text-align: center;
}
.ProductCard img {
    object-fit: cover;
    width: 150px;
    margin: auto;
}

.ProductCard .textWrap {
    display: flex;
    flex-direction: column;
}

.ProductCard .textWrap .innerContent {
    margin-top: 57px;
    padding-top: 0px;
    padding-left: 29px;
    text-align: center;
}

@media screen and (max-width: 800px) {
    .ProductCard .textWrap {
        min-height: 180px;
    }
}
