.Home .Header {
    position: relative;
    
}

.Home .Header .caruselItem {
    width: 100%;
    height: auto;
    /*padding: 0px*/
    
}

 .Home .Header .caruselItem.slideFour button{
    position: absolute;
    bottom: 10%;
    left: 20%;
} 

.Home .Header .caruselItem.slideOne button{
    position: absolute;
    bottom: 5%;
    left: 18.5%;
} 

.Home .Header .caruselItem.slideThree button{
    position: absolute;
    bottom: 18%;
    right: 15%;
} 
.Home .Header .caruselItem.slideTwo button{
    position: absolute;
    bottom: 32%;
    left: 20%;
} 

@media screen and (max-width: 1365px) {
    .Home .Header .caruselItem.slideFour button{
        position: absolute;
        bottom: 10%;
        left: 15%;
    } 
    
    .Home .Header .caruselItem.slideOne button{
        position: absolute;
        bottom: 5%;
        left: 15%;
    } 
        .Home .Header .caruselItem.slideThree button{
        position: absolute;
        bottom: 13%;
        right: 12%;
    } 

    .Home .Header .caruselItem.slideTwo button{
        position: absolute;
        bottom: 15%;
        left: 17%;
    } 
}

@media screen and (max-width: 800px) { 
    .Home .Header .caruselItem {
        width: 100%;
        height: auto;
    
    }

    .Home .Header .caruselItem.slideOne button{
        position: absolute;
        bottom: 42%;
        left: 25%;
    } 
    .Home .Header .caruselItem.slideTwo button{
        position: absolute;
        bottom: 12%;
        left: 25%;
    } 
    
    .Home .Header .caruselItem.slideThree button{
        position: absolute;
        bottom: 8%;
        left: 30%;
        right: 25%;
    } 

    .Home .Header .caruselItem.slideFour button{
        position: absolute;
        bottom: 43%;
        left: 25%;
    } 

}