@media screen and (max-width: 800px) {
    .InvestmentProducts .GridSection {
        min-width: 280px;
        width: 100%;
    }
    .InvestmentProducts .MuiGrid-grid-xs-5 {
        max-width: 100% !important;
        width: 100%;
        flex-basis: 100%;
    }
    .InvestmentProducts .CardGrid {
        padding-right: 0px !important;
        width: 100%;
    }

    .InvestmentProducts .MuiGrid-grid-sm-6 {
        width: 100%;
    }

    .InvestmentProducts .GraphSection {
        padding-bottom: 0px;
    }
}
