.SingleOffice {
    border-top: 1px solid #000;   
}

.SingleOffice.withBottomBorder {
    border-bottom: 1px solid #000;   
}

.SingleOffice .rowItem {
    flex: 1;
}