.Home .SingleCard {
    height: 560px;
    width: 100%;
    max-width: 480px;
}

.SingleCard img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    height: 295px;
}

.SingleCard .textWrap {
    height: 265px;
    min-height: 265px;
    display: flex;
    flex-direction: column;
}

.SingleCard .textWrap .innerContent {
    margin-top: 57px;
    padding-top: 0px;
    padding-left: 29px;
}
.SingleCard .textWrap .H2 {
    height: 62px;
}

.SingleCard .textWrap .bodyText {
    height: 75px;
}
.SingleCard .textWrap .Button {
    margin: auto auto 0px;
}

@media screen and (min-width: 2000px) {
    .Home .SingleCard {
        max-width: 600px;
    }
}

@media screen and (max-width: 1300px) {
    .Home .SingleCard {
        margin-right: 30px;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 800px) {
    .SingleCard .textWrap {
        min-height: 267px;
    }
    .Home .SingleCard {
        margin-bottom: 40px;
    }
    .SingleCard .textWrap .innerContent {
        margin-top: 35px;
    }
    .Home .SingleCard {
        margin: 40px auto;
    }
}
