.TimelineCard {
    height: 170px;
}
.HowToInvest .NumberBullet {
    opacity: 0.3;
    color: #c2d1d9;
    font-size: 206px !important;
    line-height: 237px;
}
.HowToInvest .OrangeTitle {
    color: #e9700e;
}
.HowToInvest .BodySection .bodyText {
    max-width: 334px;
}
