.HorizontalCard {
    max-width: 650px;
    width: 100%;
    margin-bottom: 37px;
}

.HorizontalCard img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    max-width: 260px;
    height: 262px;
}

.HorizontalCard .textWrap {
    width: 100%;
    max-width: 375px;
    height: 262px;
    display: flex;
    flex-direction: column;
}

.HorizontalCard .textWrap .innerContent {
    margin-top: 57px;
    padding-top: 0px;
}
.HorizontalCard .textWrap .H2 {
    height: 62px;
}

.HorizontalCard .textWrap .bodyText {
    height: 75px;
}
.HorizontalCard .textWrap .Button {
    margin: auto auto 0px;
}

@media screen and (min-width: 2000px) {
    .HorizontalCard {
        max-width: 800px;
    }

    .HorizontalCard .textWrap {
        max-width: 500px;
    }
}
@media screen and (max-width: 1300px) {
    .Home .HorizontalCard {
        margin: 20 auto;
    }
    .HorizontalCard .textWrap {
        min-height: 267px;
        max-width: 80%;
    }

    .Home .HorizontalCard img {
        max-width: 80%;
        object-fit: cover;
        height: 295px;
    }
    .Home .HorizontalCard .MuiGrid-item {
        width: 100%;
    }
}
@media screen and (max-width: 800px) {
    .HorizontalCard .textWrap {
        min-height: 267px;
    }

    .Home .HorizontalCard img {
        max-width: 100%;
    }
    .Home .HorizontalCard .MuiGrid-item {
        width: 100%;
    }
}
