.SubmitADeal .Header {
    min-height: 240px;
    max-height: 350px;
    background-image: url(./background.png);
}

@media screen and (max-width: 800px) { 
    .SubmitADeal .Header {
        min-height: 250px;
    }
}