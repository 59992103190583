.InvestorWhyUs .SingleCard {
    height: 354px;
}

.SingleCard .reviewSection {
    width: 354px;
    height: 100%;
}
.SingleCard .quoteImage {
    width: 53px;
    height: 53px;
}

.SingleCard .innerReviewSection {
    margin: auto;
}
@media screen and (max-width: 800px) {
    .InvestorWhyUs .SingleCard {
        width: 100%;
    }

    .InvestorWhyUs .SingleCard .reviewSection {
        width: 100%;
    }
}
