.QuestionAndAnswer {
    border-bottom: 2px solid;
    font-weight: normal;
    cursor: pointer;
}

.QuestionAndAnswer .icon {
    min-height: 21px;
    min-width: 21px;
    background-image: url(./plus.svg);
    background-size: 100%;
    background-repeat: no-repeat;
}

.QuestionAndAnswer .head {
    display: flex;
}

.QuestionAndAnswer.open .icon {
    background-image: url(./minus.svg);
}

.QuestionAndAnswer .answer {
    max-height: 0px;
    opacity: 0;
    transform: translateY(-10px);
    pointer-events: none;
}

.QuestionAndAnswer.open .answer {
    max-height: 9000px;
    opacity: 1;
    pointer-events: all;
}

.QuestionAndAnswer.open .answer img {
    max-width: 100%;
}

.QuestionAndAnswer .answer i {
    position: relative;
    height: 20px;
    width: 20px;
    display: inline-block;
    background-image: url(./info.svg);
    transform: translate(6px, 3px);
    z-index: 3;
}
.QuestionAndAnswer .answer i:hover {
    z-index: 5;
}

.QuestionAndAnswer .answer i p {
    background-color: #fff;
    position: absolute;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, .5);
    width: 400px;
    padding: 15px;
    font-style: normal;
    left: 20px;
    top: 0px;
    pointer-events: none;
    opacity: 0;
}

.QuestionAndAnswer .answer i:hover p {
    opacity: 1;
}


@media screen and (max-width: 800px) { 
    .QuestionAndAnswer {
        padding-right: 10px !important;
        padding-left: 10px !important;
    }
    
}