.ReadyToInvestSection {
    height: 408px;
    background-image: url(./background.png);
    display: flex;
    flex-direction: column;
}

.ReadyToInvestSection .plainText {
    max-width: 583px;
    margin: 0px auto;
}

@media screen and (max-width: 800px) {
    .ReadyToInvestSection {
        height: 260px;
        padding-left: 20px;
        padding-right: 20px;
    }
}
