.Footer {
    font-weight: 500;
    position: relative;
}

.Footer .withBackground {
    /* background-image: url(./BBB.png);
    background-position: calc(88% - 186px) 100px;
    background-size: 130px; */
}

.Footer .bbb {
    position: absolute;
    top: 120px;
    width: 130px;
    right: calc(20%) 
}

.Footer .darkerBackground {
    height: 20px;
}

.Footer .row {
    justify-content: space-between;
    align-items: center;
}

.Footer .row.secondRow {
    width: 522px;
    height: 40px;
}

.Footer .Button {
    min-width: 80px;
    padding-right: 20px;
    padding-left: 20px;
    border-width: 1px;
}

.Footer input {
    border-width: 1px;
    border-style: solid;
    border-color: #dedede;
    margin-right: 5px;
    padding-left: 20px;
    min-width: 150px;
    height: 38px;
}
.Footer .menuItem.active {
    text-shadow: 0px 0px .75px;
}

.Footer .privacy {
    color: gray;
    margin: 0 auto;
    max-width: 600px;
}

@media screen and (max-width: 800px) { 

    .Footer .row.items {
        display: inline-block;
    }
    
    .Footer .row.secondRow {
        width: auto;
    }

    .Footer .menuItem {
        padding: 7.5px 15px;
        display: inline-block;
    }

    .Footer input {
        margin-right: 0px;
        padding-left: 20px;
        min-width: 110px;
        margin-right: 5px;
    }

    .Footer .subscribeWrap {
        display: inline-flex;
        flex-direction: row;
    }

    .Footer .Button {
        min-width: 50px;
        padding: 0px 10px;
    }
       
    .Footer .row.secondRow {
        height: auto;
    }

    .Footer .logo {
        max-width: 240px;
        margin-bottom: 60px;
    }

    .Footer .withBackground {
        background-position: center calc(100% - 120px);
        background-size: 100px;
    }


    .Footer .bbb {
        position: absolute;
        top: unset;
        right: calc(50% - 50px);
        bottom: 164px;
        width: 100px;
    }
}