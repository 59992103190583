.AttachmentField {
    flex-grow: 1;
    flex: 1;
}

.AttachmentField .row {
    position: relative;
}

.AttachmentField .label  {
    min-width: 120px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.AttachmentField .textBox {
    flex-grow: 1;
    height: 100%;
    border: none;
    padding: 0px 10px;
    min-width: 400px;
    background: rgba(255, 255, 255, .75);
    display: flex;
    align-items: center;
    height: 35px;
    background-image: url(./clip.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 95px;
}

.AttachmentField input.file {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    width: 100%;
}

.AttachmentField .fileName {
    padding: 5px 10px;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #fff;
}

.AttachmentField .fileName img {
    height: 15px;
    cursor: pointer;
}

.AttachmentField .attachments {
    padding-left: 130px;
}

@media screen and (max-width: 800px) { 

    .AttachmentField {
        padding-top: 15px;
        height: 40px;
    }

    .AttachmentField .label  {
        padding-bottom: 5px;
        justify-content: flex-start;
    }
}