.SingleCard {
    
}

.SingleCard img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.SingleCard .textWrap {
    min-height: 320px;
    display: flex;
    flex-direction: column;
}

.SingleCard .textWrap .Button {
    margin: auto auto 0px;
}

@media screen and (max-width: 800px) { 
    .SingleCard .textWrap {
        min-height: 267px;
    }
}