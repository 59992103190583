.InvestorAboutUs .contentSection {
    max-width: 680px;
}

.rightContentSection {
    margin-right: 40px !important;
    margin-left: 0px !important;
}

.leftContentSection {
    margin-right: 0px !important;
    margin-left: 40px !important;
}
@media screen and (min-width: 2000px) {
    .InvestorAboutUs .contentSection {
        max-width: 1000px;
        width: 100%;
    }
}

@media screen and (max-width: 1250px) {
    .InvestorAboutUs .contentSection {
        max-width: 450px;
    }
}
@media screen and (max-width: 1050px) {
    .InvestorAboutUs .contentSection {
        max-width: 350px;
    }
}
@media screen and (max-width: 850px) {
    .InvestorAboutUs .contentSection {
        max-width: 100%;
        width: 100%;
    }

    .InvestorAboutUs .leftContentSection {
        padding-left: 0px;
        padding-top: 20px !important;
        margin-left: 0px !important;
    }
}
