.OurTeam .TeamMember {
    /* display: flex; */
    max-width: 50%;
}

.OurTeam .TeamMember.big {
    max-width: unset;
}

.OurTeam .TeamMember .imageWrap {
    background: #e8e8e8;
    border-radius: 50%;
    height: 130px;
    min-width: 130px;
    max-width: 130px;
    overflow: hidden;
}

.OurTeam .TeamMember.big .imageWrap {
    height: 210px;
    min-width: 210px;
    max-width: 210px;
}

.OurTeam .TeamMember .imageWrap img {
    max-height: 100%;
}

.OurTeam .TeamMember .textWrap {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
}



@media screen and (max-width: 800px) { 

    .OurTeam .TeamMember {
        max-width: unset;
    }


    .OurTeam .TeamMember .textWrap {
        padding-top: 10px;
    }

    .OurTeam .TeamMember.big {
        flex-direction: column;
    }

    .OurTeam .TeamMember .imageWrap {
        height: 90px;
        min-width: 90px;
        max-width: 90px;
        margin-right: auto;
        margin-left: auto;
    }

    .OurTeam .TeamMember.big .imageWrap {
        height: 110px;
        min-width: 110px;
        max-width: 110px;
    }

    .OurTeam .mobileCenter {
        text-align: center;
    }
    
}