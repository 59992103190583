.ReviewCard {
    height: 354px;
}

.ReviewCard .reviewSection {
    width: 100%;
    max-width: 354px;
    height: 100%;
}
.ReviewCard .quoteImage {
    width: 53px;
    height: 53px;
}

.ReviewCard .innerReviewSection {
    margin: auto;
}

@media screen and (min-width: 2000px) {
    .ReviewCard .reviewSection {
        max-width: 500px;
    }
}
@media screen and (min-width: 2400px) {
    .ReviewCard .reviewSection {
        max-width: 600px;
    }
}
@media screen and (max-width: 800px) {
    .ReviewCard .reviewSection {
        max-width: 100%;
    }
}
