.DownloadAppSection {
    height: 256px;
    background-image: url(./background.png);
    display: flex;
    flex-direction: column;
}

.DownloadAppSection .plainText {
    max-width: 557px;
    margin: 0px auto;
}

.DownloadAppSection .appImages {
    margin: 0px auto;
}
@media screen and (max-width: 800px) {
    .DownloadAppSection {
        height: 220px;
        padding-left: 20px;
        padding-right: 20px;
    }
}
