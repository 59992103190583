.WhatIsMIC .Header {
    height: 317px;
    background-image: url(./background.png);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.WhatIsMIC .Header .plainText {
    max-width: 646px;
    margin: 0px auto;
}
@media screen and (max-width: 800px) {
    .WhatIsMIC .Header {
        height: 200px;
    }
    .WhatIsMIC .marginOnMobile {
        margin: 20px;
        width: 90%;
    }
}
