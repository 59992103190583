.Appraisers .PlansTable .tableWrap {
    position: relative;
}

.Appraisers .PlansTable table {
    width: 100%;
    position: relative;
    border-collapse: collapse;
}

.Appraisers .PlansTable .thead div {
    width: 150px;
    margin-right: 2px;
    cursor: pointer;
}

.Appraisers .PlansTable .underLine {
    height: 10px;
    position: absolute;
    right: 2px;
    left: 0px;
    top: 110px;
    z-index: 1;
}

.Appraisers .PlansTable table td {
    padding: 15px 10px;
    height: 32px;
    border: none;
    border-width: 0px;
}

.Appraisers .PlansTable table td:nth-of-type(1){
    width: 20%;
}
.Appraisers .PlansTable table td:nth-of-type(2){
    width: 17%;
}
.Appraisers .PlansTable table td:nth-of-type(3){
    width: 20%;
}
.Appraisers .PlansTable table td:nth-of-type(4){
    width: 30%;
}
.Appraisers .PlansTable table td:nth-of-type(5){
    width: 13%;
}

.Appraisers .PlansTable .thead {
    padding-bottom: 10px;
    height: 70px;
    display: flex;
}

.Appraisers .PlansTable table tbody {
    padding-left: 2px;
}

.Appraisers .PlansTable table th {
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    cursor: pointer;
    text-transform: uppercase;
    border-left: 3px solid #fff;
}

.Appraisers .tableRow {
    display: flex;
    overflow: auto;
    height: 5rem;
    /*overflow: scroll;*/
}



@media screen and (max-width: 800px) { 
    .Appraisers .PlansTable {
        padding-right: 0px;
    }

    .Appraisers .PlansTable table td {
        padding: 10px;
    }

    .Appraisers .PlansTable .underLine {
        top: 90px;
    }

    .Appraisers .PlansTable .thead {
        height: 60px;
        min-width: 552px;
    }
    
    .Appraisers .PlansTable .tableWrap {
        overflow-x: scroll;
    }

    .Appraisers .PlansTable .tableWrap table {
        min-width: 550px;
    }

    .Appraisers .PlansTable .underLine {
        right: unset;
        left: 0px;
        min-width: 550px;
        top: 90px;
    }
}