.BodySection {
    background-image: none;
}

.BodySection .aboutUs {
    margin-top: -120px;
}

.BodySection .smallImage {
    max-width: 398px;
    width: 398px;
    height: 311px;
}
.BodySection .contentSection {
    margin-left: 60px;
}

.BodySection .bodyText {
    max-width: 80%;
    margin: 0px auto;
    text-align: center;
}

@media screen and (max-width: 800px) {
    .BodySection {
        background-repeat: no-repeat;
        background-position: left 100%;
        background-size: 50% auto;
        padding-bottom: 100px;
    }

    .BodySection .ourStory {
        min-height: 150px;
        margin-top: 0px;
    }

    .BodySection .carotsImage {
        max-width: 100%;
    }
    .BodySection .MuiGrid-item {
        width: 100%;
    }
    .BodySection .smallImage {
        max-width: 100% !important;
        width: 100%;
        height: 311px;
    }
    .BodySection .contentSection {
        margin-left: 0px;
    }
}
