.InvestorNewsAndEvents .BodySection {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.InvestorNewsAndEvents .BodySection .reportButtonSection {
    margin-right: 0px;
    margin-left: 150px;
}

.InvestorNewsAndEvents .BodySection .bottomTextSection {
    margin: auto;
    text-align: left;
    margin-bottom: 120px;
}

@media screen and (max-width: 800px) {
    .InvestorNewsAndEvents .BodySection {
        height: 120px;
    }
}
