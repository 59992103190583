.StayConnected {
    background-image: url(./background.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.StayConnected .plainText {
    max-width: 600px;
    margin: 0px auto;
}

.StayConnected .Button {
    width: 220px;
    margin: 0px auto;
}
