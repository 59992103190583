.Facts {
    text-align: center;
    padding: 60px 40px;
}

.Facts .plainText {
    max-width: 400px;
    margin: auto;
}

.Facts .row {
    justify-content: space-evenly;
}

.Facts .singleFactContainer {
    text-align: left;
    align-items: center;
}

.Facts .singleFactContainer img {
    max-height: 80px;
    max-width: 80px;
}

.Facts .singleFactContainer:first-of-type img {
 /*    height: 70px; */
}

.Facts .singleFactContainer:last-of-type img {
   /*  height: 65px; */
}

.Facts .singleFactContainer .textWrap {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

@media screen and (max-width: 800px) { 
    .Facts {
        text-align: center;
        padding: 40px 20px;
    }

    .Facts .factsRow {
        display: table;
        width: 100%;
    }

    .Facts .singleFactContainer {
        text-align: left;
        padding-top: 20px;
        width: 50%;
        display: inline-flex;
        flex-direction: row;
    }

    .Facts .singleFactContainer div {
        flex: 1;
    }

    .Facts .singleFactContainer img {
        max-height: 50px;
        max-width: 50px;
        margin-left: auto;
        display: block;
        margin-right: 20px; 
    }
    
    .Facts .singleFactContainer:first-of-type img {
        height: 45px;
    }
    
    .Facts .singleFactContainer:last-of-type img {
     /*    height: 40px; */
    }

    .Facts .singleFactContainer .textWrap {
        padding-left: 0;
    }
}