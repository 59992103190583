.ProductsSection {
}
.ProductsSection .line {
    width: 100%;
    height: 1px;
}
.ProductsSection .logoImage {
    max-height: 72px;
    max-width: 183px;
    margin-right: 40px;
    object-fit: none;
}

.ProductsSection .logoImageOne {
    max-height: 96px;
    max-width: 120px;
    margin-right: 40px;
    object-fit: none;
}
.ProductsSection .logoImageTwo {
    max-height: 92px;
    max-width: 330px; 
    margin-right: 40px;
    object-fit: none;
}
.ProductsSection .logoImageThree {
    max-height: 92px;
    max-width: 220px;
    margin-right: 40px;
    object-fit: none;
}

.ProductsSection .slideTwoBody {
    min-width: 280px;
    max-width: 350px;
}
@media screen and (max-width: 800px) {
    .slideTwoBodyTwo {
        min-width: 280px;
        max-width: 350px;
    }
}
