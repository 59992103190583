.InvestorAboutUs .Header {
    min-height: 512px;
    background-image: url(./background.png);
}

@media screen and (min-width: 1800px) {
    .InvestorAboutUs .Header {
        min-height: 700px;
    }
}
@media screen and (max-width: 800px) {
    .InvestorAboutUs .Header {
        height: 200px;
        min-height: 150px;
        padding: 20px 0% 0px 0%;
    }
}
