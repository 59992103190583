.Home .Header {
    position: relative;
}

.Home .Header .headerSection {
    height: 650px;
    padding: 0px;
}

.Home .Header .headerSection.slideOne {
     background-image: url(./background.png);
} 
.Home .Header .slideOneInnerContent {
    padding-left: 10%;
    padding-top: 190px;
}
.buttonRow {
    margin-top: 52px;
    font-size: 14px;
    text-decoration: underline;
}

.play-icon {
    vertical-align: middle;
}

.Home .Header .slideTwo {
    height: 700px;
    object-fit: contain;
}

.slideTwoInnerContent {
    float: right;
    width: 350px;
    padding-right: 190px;
    padding-top: 180px;
}

.slideTwoBody {
    line-height: 24px;
    font-weight: normal;
    padding-right: 50px;
}
.slideTwoButtonRow {
    margin-top: 32px;
}

.Home .Header .popup {
    position: fixed;
    width: 280px;
    height: 140px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
}
.Home .Header .popup_inner {
    position: absolute;

    margin: 10px;
    padding: 10px;
    background: white;
}

@media screen and (min-width: 2000px) {
    .Home .Header .slideTwo {
        width: 1000px;
    }
}

@media screen and (max-width: 1365px) {
    .Home .Header .headerSection.slideTwo {
        background-color: white;
    }
    .Home .Header .slideTwo {
        height: 630px;
        object-fit: contain;
    }
    .Home .Header .headerSection.slideTwo .slideTwoImage {
        padding-left: 30px;
        width: 550px;
    }
}

@media screen and (max-width: 1280px) {
    .Home .Header .headerSection.slideTwo .slideTwoImage {
        width: 430px;
    }
    .Home .Header .slideTwo {
        height: auto;
        text-align: center;
        object-fit: contain;
    }
    .Home .Header .headerSection.slideTwo .slideTwoInnerContent {
        padding-top: 60px;
        padding-right: 50px;
        padding-left: 50px;
        padding-bottom: 30px;
    }
    .Home .Header .headerSection.slideTwo .slideTwoInnerContent .slideTwoBody {
        padding-right: 0;
    }
    .Home .Header .noLeftMarginOnMobile {
        padding-left: 0;
    }
}

@media screen and (max-width: 800px) {
    .Home .Header .headerSection {
        height: 420px;
        padding: 0px 20px 0px 20px;
        background-color: #b0d584;
        background-size: 400px;
        background-position: center 120%;
        text-align: center;
    }

    .Home .Header .headerSection.slideOne {
        background-image: url(./mobilebackground.png);
    }
    .Home .Header .headerSection.slideOne .slideOneInnerContent {
        padding-top: 30px;
        padding-left: 0px;
    }
    .Home .Header .buttonRow {
        margin-top: 30px;
    }

    .Home .Header .slideTwo {
        height: 570px;
    }
    .Home .Header .headerSection.slideTwo .slideTwoInnerContent {
        padding-top: 10px;
    }

    .Home .Header .headerSection.slideTwo {
        background-color: white;
    }
    .Home .Header .headerSection.slideTwo .paddingLeftXXL {
        padding-left: 0px;
    }
    .Home .Header .headerSection.slideTwo .slideTwoImage {
        width: 320px;
    }
}
