.InvestmentProducts .SingleCard {
    height: 472px;
}

.InvestmentProducts .SingleCard .reviewSection {
    width: 458px;
    height: 100%;
}
.InvestmentProducts .SingleCard .topImage {
    width: 173px;
    height: 173px;
    object-fit: contain;
}
.InvestmentProducts .SingleCard .innerContentSection {
    min-height: 60px;
}

@media screen and (max-width: 800px) {
    .InvestmentProducts .SingleCard {
        height: auto;
    }

    .InvestmentProducts .SingleCard .reviewSection {
        width: 100%;
        height: auto;
    }
}
