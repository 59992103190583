.LendingMap .UnbeatableGrowthSection {
    display: flex;
    flex-direction: column;
}

.LendingMap .UnbeatableGrowthSection .plainText {
    max-width: 700px;
    margin-left:0xp;
}

.LendingMap .UnbeatableGrowthSection .buttonRow{
    margin-top:60px;
}
.LendingMap .UnbeatableGrowthSection .mapImage{
    width:1008px;
    margin : 100px auto;
}
@media screen and (max-width: 800px) { 
    .LendingMap .UnbeatableGrowthSection {
        height: 120px;
    }
}