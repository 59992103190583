body {
    color: #083e5d;
}

.lightText {
    color: #fff;
}

.darkText {
    color: #083e5d;
}

.blackishText {
    color: #404957;
}

.greenText {
    color: #73b555;
}

.darkGreenText {
    color: #008000;
}

.orangeText {
    color: #ff8c00;
}

.whiteBackground {
    background-color: white;
}
.primaryBackground {
    background-color: #b0d585;
}

.hoverPrimaryBackground:hover {
    background-color: #b0d585;
}

.primaryStrongBackground {
    background-color: #73b555;
}

.ctaBackground {
    background-color: #f7921e;
}

.darkerBackground {
    background-color: #023a5e;
}

.darkBackground {
    background: radial-gradient(#096ca8, #013b5f);
}

.darkishBackground {
    background-color: #7c8ba4;
}

.blackishBackground {
    background-color: #404957;
}

.lightishBackground {
    background-color: #f2f5f7;
}
.lighGrayBackground {
    background-color: #fafafa;
}

.lightBackground {
    background-color: #fff;
}

.lightBlueBackground {
    background-color: #dee4e7;
}
.dividerBackground {
    background-color: #c2d1d9;
}

.borderDark {
    border-color: #073d5d;
    border-bottom-color: #073d5d;
}

.borderPrimaryStrong {
    border-color: #73b555;
    border-bottom-color: #73b555;
}
