@media screen and (max-width: 800px) {
    .OurPerformance .MuiGrid-grid-xs-5 {
        max-width: 100% !important;
        flex-basis: 100%;
        width: 100%;
    }
    .OurPerformance .ZeroRightPaddingOnMobile {
        padding-right: 0px;
    }
}
