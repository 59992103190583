.Contact .Header {
    background-image: url('./background.png');
    min-height: 240px;
    max-height: 350px;
}

@media screen and (max-width: 800px) { 
    .Contact .Header {
        min-height: 140px;
    }
}