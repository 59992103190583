.InvestmentStrategy .SingleCard {
    height: 472px;
}

.InvestmentStrategy .SingleCard .reviewSection {
    width: 458px;
    height: 100%;
}
.InvestmentStrategy .SingleCard .topImage {
    width: 173px;
    height: 173px;
    object-fit: contain;
}
.InvestmentStrategy .SingleCard .innerContentSection {
    min-height: 60px;
}

@media screen and (max-width: 800px) {
    .InvestmentStrategy .SingleCard .reviewSection {
        width: 100%;
        height: 100%;
    }
}
