@media screen and (max-width: 800px) {
    .InvestmentStrategy .MuiGrid-grid-xs-6,
    .InvestmentStrategy .MuiGrid-grid-xs-5 {
        width: 100%;
        max-width: 100%;
        padding-right: 0px;
        flex-basis: 100%;
    }

    .InvestmentStrategy .GridSection {
        min-width: 280px;
        padding-right: 0px;
        width: 100%;
    }
    .MuiGrid-grid-xs-5 {
        max-width: 100% !important;
    }
    .InvestmentStrategy .CardGrid {
        padding-right: 0px !important;
    }
}
