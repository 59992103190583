.MortgageCard {
    max-width: 100%;
}

.MortgageCard img {
    max-width: 100%;
}

@media screen and (max-width: 800px) { 
    .MortgageCard .Button {
        width: 100%;
    }
}