.QuoteSection {

}

.QuoteSection .darkBackground {
    text-align: center;
}

.QuoteSection .deal.withBackground {
    min-height: 800px;
    background-image: url(./deal.webp);
}

.QuoteSection .primaryBackground {
    width: 100%;
}

.QuoteSection .line {
    width: 2px;
    min-height: 200px;
}

.QuoteSection .rowItem {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.QuoteSection .rowItem .Button {
    margin-top: auto;
    margin-right: auto;
}

@media screen and (max-width: 800px) { 
    .QuoteSection .line {
        width: calc(100% - 40px);
        margin: auto;
        min-height: 2px;
        opacity: 0;
    }

    .QuoteSection .primaryBackground {
        padding-bottom: 0;
        padding-top: 0px;
    }

    .QuoteSection .deal.withBackground {
        min-height: 650px;
    }
    
    .QuoteSection .Button {
        width: 100%;
    }

    .QuoteSection .ready {
        padding-top: 30px;
        font-size: 30px;
    }
}