.SingleStep {

}


.SingleStep img {
    width: 100%;
    height: 240px;
    object-fit: contain;
}

.SingleStep .circle {
    border-radius: 50%;
    height: 100px;
    width: 100px;
    margin: auto;
    position: relative;
}


@media screen and (max-width: 800px) { 

    .SingleStep .imageWrap {
        height: 140px;
        position: relative;
    }

    .SingleStep img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 140px;
    }

    .SingleStep .circle {
        height: 50px;
        width: 50px;
    }
    
}