.BodySection {
    background-image: none;
}

.BodySection .smallImage {
    max-width: 398px;
    width: 398px;
    height: 311px;
}
.BodySection .contentSection {
    margin-left: 60px;
}
.BodySection .topGridSection {
    height: 579px;
}
.BodySection .innerContent {
    max-width: 496px;
}
.WhatIsMIC .BodySection .topSection {
    max-width: 754px;
}

.WhatIsMIC .BodySection .rightSideImage {
    width: 608px;
    height: 811px;
    object-fit: cover;
}

.WhatIsMIC .BodySection .caruselItem {
    background-color: white;
}
.WhatIsMIC .BodySection .Carusel .owl-carousel .owl-stage-outer {
    height: 811px;
}
.WhatIsMIC .BodySection .Carusel .owl-theme .owl-dots button {
    background: #c2d1d9;
    width: 15px;
    height: 15px;
}
.WhatIsMIC .BodySection .owl-dot.active span {
    background: #424b5a !important;
}

button.owl-dots.active {
    background: #424b5a !important;
}

.WhatIsMIC .BodySection .Carusel .owl-theme .owl-dots {
    width: 60%;
}

.WhatIsMIC .BodySection .BottomImage {
    width: 100%;
    height: 640px;
    object-fit: cover;
}

@media screen and (max-width: 1400px) {
    .WhatIsMIC .BodySection .rightSideImage {
        width: 450px;
        height: 811px;
    }
}
@media screen and (min-width: 1800px) {
    .WhatIsMIC .BodySection .topSection {
        padding-right: 80px;
        max-width: 900px;
    }
}

@media screen and (min-width: 2000px) {
    .WhatIsMIC .BodySection .topSection {
        max-width: 1200px;
    }
}

@media screen and (min-width: 2400px) {
    .WhatIsMIC .BodySection .topSection {
        max-width: 1500px;
    }
}

@media screen and (max-width: 2000px) {
    .WhatIsMIC .BodySection .topSection {
        max-width: 1000px;
    }
}
@media screen and (max-width: 1800px) {
    .WhatIsMIC .BodySection .topSection {
        max-width: 800px;
    }
}

@media screen and (max-width: 1500px) {
    .WhatIsMIC .BodySection .topSection {
        max-width: 700px;
    }
}
@media screen and (max-width: 1300px) {
    .WhatIsMIC .BodySection .topSection {
        max-width: 500px;
    }
}
@media screen and (max-width: 1000px) {
    .WhatIsMIC .BodySection .topSection {
        max-width: 400px;
    }
}
@media screen and (max-width: 900px) {
    .WhatIsMIC .BodySection .topSection {
        max-width: 350px;
    }
}
@media screen and (max-width: 850px) {
    .WhatIsMIC .BodySection {
        padding-bottom: 0;
    }
    .WhatIsMIC .BodySection .topSection {
        padding-top: 20px !important;
        max-width: 90%;
        width: 90%;
    }

    .BodySection .ourStory {
        min-height: 150px;
        margin-top: 0px;
    }

    .BodySection .carotsImage {
        max-width: 100%;
    }
    .WhatIsMIC .BodySection .rightSideImage {
        width: 100%;
        height: 400px;
    }
    .WhatIsMIC .BodySection .Carusel .owl-theme .owl-dots {
        margin-left: auto;
        margin-right: auto;
    }

    .WhatIsMIC .BodySection .BottomImage {
        height: 300px;
    }
    .WhatIsMIC .BodySection .MuiGrid-item,
    .WhatIsMIC .BodySection .MuiGrid-grid-xs-6 {
        width: 100%;
        max-width: 100%;
        padding-right: 0px;
        flex-basis: 100%;
    }
    .WhatIsMIC .BodySection .zeroMarginLeftOnMobile {
        padding-left: 0px;
        padding-top: 20px;
    }

    .WhatIsMIC .BodySection .zeroPaddingBottomOnMobile {
        padding-bottom: 0px !important;
    }
    .WhatIsMIC .BodySection .marginOnMobile {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
}
