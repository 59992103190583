.AreaSection iframe {
    width: 100%;
    height: 650px;
}
@media screen and (max-width: 800px) {
    .AreaSection .MuiGrid-grid-xs-2 {
        max-width: 33% !important;
        flex-basis: 33%;
        width: 33%;
        padding-left: 0px;
    }
}
