.MainSection {
    background-image: url(./poke.png);
    background-repeat: no-repeat;
    background-position: right 447px;
    background-size: 560px;
}

@media screen and (max-width: 800px) { 
    .MainSection {
        background-position: right calc(100% - 50px);
        background-size: 350px;
        padding-bottom: 100px;
    }
}