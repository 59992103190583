.BlogPostCard {
    
}

.BlogPostCard .imageWrap {
    height: 380px;
}

.BlogPostCard .imageWrap img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
}