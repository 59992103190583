.BodySection {
    background-image: url(./greenheart.png);
    background-repeat: no-repeat;
    background-position: left 90%;
    background-size: 27% auto;
}

.BodySection .ourStory {
    min-height: 240px;
    margin-top: -200px;
}

.BodySection .carotsImage {
    max-width: 100%;
}

@media screen and (max-width: 1400px) {
    .InvestorAboutUs .pagePadding-Investor {
        padding-left: 60px;
        padding-right: 60px;
    }
}

@media screen and (max-width: 1250px) {
    .InvestorAboutUs .pagePadding-Investor {
        padding-left: 30px;
        padding-right: 30px;
    }
}
@media screen and (max-width: 800px) {
    .BodySection {
        background-repeat: no-repeat;
        background-position: left 100%;
        background-size: 50% auto;
        padding-bottom: 100px;
    }

    .BodySection .ourStory {
        min-height: 150px;
        margin-top: 0px;
    }

    .BodySection .carotsImage {
        max-width: 100%;
    }
}
