.AnalystRating .RatingGraphSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.AnalystRating .RatingGraphSection .middleHeader {
    max-width: 662px;
    margin: 0px auto;
}

.AnalystRating .RatingGraphSection .plainText {
    max-width: 557px;
    margin: 0px auto;
}

.AnalystRating .RatingGraphSection .buttonRow {
    text-align: right;
}
.AnalystRating .RatingGraphSection .captionSection {
    max-width: 1000px;
    margin: auto;
    text-align: left;
}

.AnalystRating .RatingGraphSection .rightM {
    margin: 30px;
}

@media screen and (max-width: 1400px) {
    .AnalystRating .marginOnMobile {
        width: 95%;
        margin: auto;
        padding: 0;
    }
}

@media screen and (max-width: 1200px) {
    .AnalystRating .RatingGraphSection .MuiGrid-grid-xs-8 {
        max-width: 100% !important;
        flex-basis: 100%;
        width: 100%;
        padding-left: 0px;
    }
    .AnalystRating .RatingGraphSection .MuiGrid-grid-xs-4 {
        max-width: 80% !important;
        flex-basis: 80%;
        width: 80%;
        padding-left: 0px;
    }

    .AnalystRating .RatingGraphSection .MuiGrid-grid-xs-6 {
        max-width: 40% !important;
        flex-basis: 40%;
        width: 40%;
        padding-left: 0px;
    }
}

@media screen and (max-width: 1000px) {
    .AnalystRating .RatingGraphSection .MuiGrid-grid-xs-8 {
        max-width: 100% !important;
        flex-basis: 100%;
        width: 100%;
        padding-left: 0px;
    }
    .AnalystRating .RatingGraphSection .MuiGrid-grid-xs-4 {
        max-width: 80% !important;
        flex-basis: 80%;
        width: 80%;
        padding-left: 0px;
    }

    .AnalystRating .RatingGraphSection .MuiGrid-grid-xs-6 {
        max-width: 50% !important;
        flex-basis: 50%;
        width: 50%;
        padding-left: 0px;
    }
}
@media screen and (max-width: 800px) {
    .AnalystRating .RatingGraphSection .MuiGrid-grid-xs-4,
    .AnalystRating .RatingGraphSection .MuiGrid-grid-xs-6,
    .AnalystRating .RatingGraphSection .MuiGrid-grid-xs-8 {
        max-width: 100% !important;
        flex-basis: 100%;
        width: 100%;
        padding-left: 0px;
    }
    .AnalystRating .marginOnMobile {
        width: 90%;
        margin: 20px auto;
        padding: 0;
    }
}
