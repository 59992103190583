.EventCard {
    min-height: 400px;
}

.EventCard .textWrap, 
.EventCard .imageWrap {
    flex: 1;
}

.EventCard .textWrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.EventCard .imageWrap img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.EventCard .textWrap .Button {
    width: 50px;
    min-width: 120px;
    margin: 5px;
}

.EventCard .textWrap .paddingRightL {
    padding-right: 10px;
}

.EventCard .textWrap .paddingLeftL {
    padding-left: 10px;
}
@media screen and (max-width: 800px) { 
    .EventCard .imageWrap {
        min-height: 150px;
        position: relative;
    }
    .EventCard .imageWrap img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    
    .EventCard .textWrap .Button {
        width: 50%;
        padding: 0px;
    }
}