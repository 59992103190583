.LoyaltyPrograms .Header {
    min-height: 250px;
    background-image: url(./background.png);
}

@media screen and (max-width: 800px) { 
    .LoyaltyPrograms .Header {
        min-height: 200px;
        background-image: url(./background-mobile.png);
        background-position: bottom left;
    }
}
