.PastPortfolioSection {
    height: 502px;
    background-image: url(./background.png);
    display: flex;
    flex-direction: column;
}

.PastPortfolioSection .innerContent {
    max-width: 360px;
}

.PastPortfolioSection .buttonRow {
    margin-top: 20px;
}
@media screen and (max-width: 800px) {
    .PastPortfolioSection {
        height: 300px;
    }
    .PastPortfolioSection .innerContent {
        float: right;
        max-width: 250px;
        margin-right: 5%;
        padding-top: 10px;
        font-size: 25px !important;
    }
}
@media screen and (max-width: 450px) {
    .PastPortfolioSection {
        height: 500px;
    }
    .PastPortfolioSection .innerContent {
        float: right;
        max-width: 180px;
        margin-right: 5%;
        margin-top: 20%;
        padding-top: 0px;
    }
}
