.HowToInvest .Header {
    height: 324px;
    background-image: url(./background.png);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.HowToInvest .Header .plainText {
    max-width: 646px;
    margin: 0px auto;
}
@media screen and (max-width: 800px) {
    .HowToInvest .Header {
        height: 200px;
    }
    .HowToInvest .BodySection .InnerContent {
        width: 90% !important;
    }
}
