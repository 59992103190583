.InvestmentHub .headerSection {
    height: 70px;
}

.InvestmentHub .whatIsMicSection {
    background-image: url(./image1.png);
    width: 847px;
    height: 639px;
    position: relative;
}

.InvestmentHub .bottomInnerSection {
    position: absolute;
    bottom: 0;
}
.InvestmentHub .smallerColumnSection {
    height: 304px;
    width: 262px;
    position: relative;
}

.InvestmentHub .greenLine {
    width: 465px;
    height: 6px;
}

.InvestmentHub .shortGreenLine {
    width: 200px;
    height: 6px;
}
.InvestmentHub .investmentStrategiesSection {
    background-image: url(./image2.png);
}

.InvestmentHub .investWithGinkgoSection {
    background-image: url(./image3.png);
}
@media screen and (min-width: 2000px) {
    .InvestmentHub .whatIsMicSection {
        width: 800px;
    }
    .InvestmentHub .smallerColumnSection {
        width: 400px;
    }
}

@media screen and (min-width: 2200px) {
    .InvestmentHub .whatIsMicSection {
        width: 1200px;
    }
    .InvestmentHub .smallerColumnSection {
        width: 500px;
    }
}
@media screen and (max-width: 1400px) {
    .InvestmentHub .whatIsMicSection {
        width: 700px;
    }
}
@media screen and (max-width: 1200px) {
    .InvestmentHub .whatIsMicSection {
        width: 500px;
    }
}
@media screen and (max-width: 1000px) {
    .InvestmentHub .whatIsMicSection {
        width: 400px;
    }

    .InvestmentHub .greenLine {
        width: 200px;
        height: 6px;
    }
}
@media screen and (max-width: 900px) {
    .InvestmentHub .whatIsMicSection {
        width: 100%;
        height: 304px;
    }
    .InvestmentHub .smallerColumnSection {
        width: 100%;
    }
    .InvestmentHub .MuiGrid-item {
        width: 100%;
    }

    .InvestmentHub .zeroLeftPaddingMobile {
        padding-left: 0px !important;
    }
    .InvestmentHub .hiddenOnMobile {
        display: none;
        height: 30px;
    }
    .InvestmentHub .subHeadersection {
        height: 30px;
    }
}
