.Carusel {
    
}

.Carusel .owl-theme .owl-nav {
    margin-top: 0px;
    height: 0;
}

.Carusel .owl-theme .owl-nav.disabled + .owl-dots,
.Carusel .owl-theme .owl-dots {
    margin-top: 0;
    position: absolute;
    margin-bottom: 0px;
    padding-bottom: 0px;
    right: 0;
    left: 0;
 /*    bottom: 20px; */
    bottom: 15%;
}


.Carusel .owl-theme .owl-dots button {
    height: 20px;
    width: 20px;
    border: 2px solid #fff;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 7.5px;
}

.Carusel.darkNav .owl-theme .owl-dots button {
    border-color: #CFD3D8;
}

.Carusel .owl-theme .owl-dots button span {
    height: 12px;
    width: 12px;
    margin: 0px;
    background: none;
}

@media screen and (min-width: 800px) { 
    .Carusel .owl-theme .owl-dots .owl-dot:hover span {
        background: #fff;
    }
    
    .Carusel.darkNav .owl-theme .owl-dots .owl-dot:hover span {
        background: #CFD3D8;
    }
}

.Carusel .owl-theme .owl-dots .owl-dot.active span {
    background: #fff;
}

.Carusel.darkNav .owl-theme .owl-dots .owl-dot.active span {
    background: #CFD3D8;
}

.Carusel .arrowRight,
.Carusel .arrowLeft {
    position: absolute;
    height: 60px;
    top: calc(50% - 60px);
}

.Carusel .arrowRight {
    right: 40px;
}

.Carusel .arrowLeft {
    left: 40px;
}


@media screen and (max-width: 800px) { 
    .Carusel .arrowRight,
    .Carusel .arrowLeft {
        display: none;
    }

    .Carusel .owl-theme .owl-dots button {
        height: 15px;
        width: 15px;
    }
    
    .Carusel .owl-theme .owl-dots button span {
        height: 7px;
        width: 7px;
    }

    .Home .Header .caruselItem {
        height: auto;
 /*       padding: 20px 40px; */
        background-size: 400px;
        background-position: center 120%;
        text-align: center;
        min-height: 550px;
    }
    .Carusel .owl-theme .owl-nav.disabled + .owl-dots,
    .Carusel .owl-theme .owl-dots {
        margin-top: 0;
        position: absolute;
        margin-bottom: 0px;
        padding-bottom: 0px;
        right: 0;
        left: 0;
     /*    bottom: 20px; */
        bottom: 2%;
    }
    
}