.FundPortfolio .GraphSection {
    display: flex;
    flex-direction: column;
}

.FundPortfolio .GraphSection .plainText {
    max-width: 557px;
    margin: 0px auto;
}

.FundPortfolio .GraphSection .captionSection {
    width: 100%;
    margin-bottom: 120px;
}

.FundPortfolio .GraphSection .PieChart {
    width: 360px;
    margin: 0px auto;
}

.FundPortfolio .GraphSection .PieChart svg {
    overflow: visible;
}
@media screen and (max-width: 800px) {
    .FundPortfolio .MuiGrid-grid-xs-8 {
        max-width: 100% !important;
        flex-basis: 100%;
        width: 100%;
        padding-left: 0px;
    }
}
