.FAQ .whiteButton {
    background: white !important;
    border: 1px solid #424b5a;
    color: #424b5a;
}

@media screen and (max-width: 800px) {
    .FAQ .GraphSection {
        width: 100%;
        padding: 0px !important;
    }

    .marginOnMobile {
        margin: 20px;
        width: 90%;
    }
}

@media screen and (max-width: 400px) {
    .FAQ .marginTopOnMobile {
        margin-top: 20px;
        padding-top: 0px;
        padding-bottom: 0px;
    }
}
