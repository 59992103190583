.OurTeam .Header {
    min-height: 400px;
    background-image: url(./background.png);
    padding: 300px 10% 0px 50%;
}


@media screen and (max-width: 800px) { 
    .OurTeam .Header {
        min-height: unset;
        padding: 50px 20px;
    }   
}