.BodySection {
    background-image: none;
}

.BodySection .smallImage {
    max-width: 398px;
    width: 398px;
    height: 311px;
}
.BodySection .contentSection {
    margin-left: 60px;
}
.BodySection .topGridSection {
    height: 579px;
}
.BodySection .innerContent {
    max-width: 496px;
}
.HowToInvest .BodySection .timelineLeftSection {
    padding-left: 0px;
    width: 418px;
}

.HowToInvest .BodySection .rightImage {
    height: 264px;
    width: 280px;
    object-fit: contain;
}

.HowToInvest .BodySection .InnerContent {
    width: 700px;
    margin: 0px auto;
    text-align: center;
}
@media screen and (max-width: 800px) {
    .HowToInvest .BodySection {
        padding-bottom: 0px;
    }
    .BodySection .ourStory {
        min-height: 150px;
        margin-top: 0px;
    }

    .BodySection .carotsImage {
        max-width: 100%;
    }
    .HowToInvest .BodySection .marginOnMobile {
        margin: 0 20px;
        width: 90%;
    }

    .HowToInvest .zeroRightPaddingMobile {
        padding-right: 0px !important;
    }
    .HowToInvest .BodySection .rightImage {
        margin-top: 30px;
        margin-left: 25%;
    }
    .HowToInvest .BodySection .buttonRow {
        text-align: center;
        margin-top: 30px;
    }

    .HowToInvest .BodySection .topSection {
        text-align: center;
    }
}
