.Button {
    color: #fff;
    height: 40px;
    min-width: 180px;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
}

.Button:hover {
    box-shadow: 0px 0px 10px rgba(0,0,0,.3);
}

.Button:active {
    box-shadow: 0px 0px 5px rgba(0,0,0,.3);
}