
.VideoSection {
    position: relative;
    margin-top: 50px;
    margin-left: 80px;
    margin-right: 80px;
    margin-bottom: 50px;
}

.VideoSection .caruselItem {
    height: 500px;
}

.VideoSection .player-wrapper {
    position: relative;
}

.VideoSection .caruselItem.slideOne {
    /* background-image: url(./picture1.jpeg); */
}

.VideoSection .caruselItem.slideTwo {
    /* background-image: url(./picture2.jpeg); */
}

.VideoSection .caruselItem.slideThree {
    /* background-image: url(./picture3.jpeg); */
}

.VideoSection .caruselItem.slideFour {
    /* background-image: url(./picture4.jpeg); */
}

@media screen and (max-width: 800px) { 
    .VideoSection .caruselItem {
        height: 410px;
        background-size: 400px;
    }

    .VideoSection .caruselItem.slideOne {
        /* background-image: url(./picture1.jpeg); */
        background-size: 400px;
    }
    
    .VideoSection .caruselItem.slideTwo {
        /* background-image: url(./picture2.jpeg); */
        background-size: 400px;
    }
    
    .VideoSection .caruselItem.slideThree {
        /* background-image: url(./picture3.jpeg); */
        background-size: 400px;
    }

    .VideoSection .caruselItem.slideFour {
        /* background-image: url(./picture4.jpeg); */
        background-size: 400px;
    }

}
