.BlogPost .Header {

}

.BlogPost .Header .row {
    align-items: center;
}


.BlogPost .Header .arrowLeft {
    width: 0; 
    height: 0; 
    border-top: 7.5px solid transparent;
    border-bottom: 7.5px solid transparent; 
    border-right:7.5px solid #073D5D; 
    margin-right: 5px;
}

.BlogPost .Header .heroWrap {
    height: 600px;
}

.BlogPost .Header .heroWrap img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}