.CardsSection {
    position: relative;
    padding-top: 80px;
}

.CardsSection .owl-carousel {
    padding-bottom: 120px;
}

@media screen and (max-width: 800px) {
    .CardsSection {
        position: relative;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .CardsSection.pagePadding {
        padding-right: 10px;
        padding-left: 10px;
    }
    
    .CardsSection .owl-carousel {
        padding-bottom: 60px;
    }

}