.SocialIcons {
    margin: auto;
    display: flex;
    flex-direction: row !important;
}

.SocialIcons .rowItem {
    flex: 1;
    padding: 0px 10px;
    cursor: pointer;
}

.SocialIcons .rowItem img {
    max-width: 100%;
}