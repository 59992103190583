.InvestorGallery .Header {
    height: 324px;
    background-image: url(./background.png);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.InvestorGallery .Header .plainText {
    max-width: 646px;
    margin: 0px auto;
}

@media screen and (max-width: 800px) {
    .InvestorGallery .Header {
        height: 200px;
    }
    .InvestorGallery .Header .marginOnMobile {
        padding: 0px !important;
        margin: 0px;
        width: 90%;
    }
}
