.Products .PlansTable .tableWrap {
    position: relative;
}

.Products .PlansTable table {
    width: 100%;
    position: relative;
    border-collapse: collapse;
}

.Products .PlansTable .underLine {
    height: 10px;
    position: absolute;
    right: 2px;
    left: 2px;
    top: 110px;
    z-index: 1;
}

.Products .PlansTable table td {
    padding: 15px;
    height: 32px;
    border: none;
    border-width: 0px;
}

.Products .PlansTable table thead tr:first-of-type {
    padding-bottom: 10px;
    height: 80px;
}

.Products .PlansTable table th {
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    cursor: pointer;
    text-transform: uppercase;
    border-left: 3px solid #fff;
}

.Products .PlansTable table th:first-of-type {
    opacity: 0;
    pointer-events: none;
}

.italic {
    font-style: italic;
  }

@media screen and (max-width: 800px) { 

    .Products .PlansTable {
        padding-right: 0px;
    }

    .Products .PlansTable .tableWrap {
        overflow-x: scroll;
    }

    
    .Products .PlansTable .underLine {
        top: 70px;
        left: 0;
        right: unset;
        min-width: 550px;
    }


    .Products .PlansTable table {
        min-width: 550px;
    }

}