.Dropdown {
    position: relative;
    cursor: pointer;
    /* width: calc(50% - 30px); */
}

.Dropdown .head {
    align-items: center;
    height: 100%;
    flex-direction: row;
}

.Dropdown .activeValue {
    padding-right: 5px;
}

.Dropdown .arrow {
    width: 0; 
    height: 0; 
    border-left: 7.5px solid transparent;
    border-right: 7.5px solid transparent;
    border-top: 7.5px solid;
}

.Dropdown .dropdownItems {
    position: absolute;
    top: calc(100% - 1px);
    display: flex;
    flex-direction: column;
    min-width: 150px;
    opacity: 0;
    pointer-events: none;
    z-index: 2;
}

.Dropdown:hover .arrow {
    transform: rotate(-180deg);
}

.Dropdown .itemsWrap {
    position: absolute;
    background-color: #fff;
    z-index: 2;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, .2);
    opacity: 0;
    pointer-events: none;
}

.Dropdown:hover .itemsWrap {
    opacity: 1;
    pointer-events: all;
}

@media screen and (max-width: 800px) {
    .Dropdown .head {
        justify-content: center;
        padding: 10px;
    }
    
    .Dropdown .arrow {
        border-top-color: inherit;
    }

    .Dropdown .itemsWrap {
        right: 0;
        left: 0;
        text-align: center;
    }
}