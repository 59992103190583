.FAQ .Header {
    min-height: 250px;
    background-image: url(./background.png);
}


@media screen and (max-width: 800px) { 
    .FAQ .Header {
        min-height: 110px;
    }
}