.OurPerformance .SingleCard {
    height: 472px;
}

.OurPerformance .SingleCard .reviewSection {
    width: 100%;
    max-width: 458px;
    height: 100%;
}
.OurPerformance .SingleCard .topImage {
    width: 173px;
    height: 173px;
    object-fit: contain;
}

@media screen and (max-width: 800px) {
    .OurPerformance .SingleCard {
        height: auto;
    }
}
