.InvestorNewsAndEvents .BodySection .SingleCard {
    height: 400px;
}
.BodySection .LongerHeight {
    height: 520px;
}
.SingleCard .innerContentWithBorder {
    border: 1px solid #c2d1d9;
    border-radius: 5px;
}

.BodeSection .SingleCard .mainImage {
    height: 268px;
}
.VerticalLine {
    width: 1px;
    height: 120px;
    margin: 0px auto;
}
@media screen and (max-width: 800px) {
}
