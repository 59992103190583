.EventsAndSeminars .headerSection {
    height: 70px;
}

.EventsAndSeminars .mainIamgeSection {
    height: 100%;
    width: 100%;
    position: relative;
    object-fit: cover;
    margin-right: 40px;
}

.EventsAndSeminars .smallerColumnSection {
    width: 262px;
    position: relative;
}

@media screen and (max-width: 1400px) {
    .EventsAndSeminars .marginOnMobile {
        padding-left: 0px !important;
        padding-right: 0px !important;
        padding-bottom: 0px !important;
        margin: 0px auto;
        width: 90%;
    }

    .EventsAndSeminars .fullWidth {
        padding-left: 0px !important;
        padding-right: 0px !important;
        margin: 0px auto;
        width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .EventsAndSeminars .MuiGrid-grid-xs-true {
        flex-basis: 100%;
    }
    .EventsAndSeminars .marginOnMobile {
        padding-left: 0px !important;
        padding-right: 0px !important;
        margin: 0px auto;
        width: 90%;
    }

    .EventsAndSeminars .fullWidth {
        padding-left: 0px !important;
        padding-right: 0px !important;
        margin: 0px auto;
        width: 100%;
    }
}
