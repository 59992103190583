.HistoricalReturn .BodySection {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.HistoricalReturn .BodySection .FirstSectionInnerContnet {
    max-width: 646px;
    margin: 0px auto;
}
.HistoricalReturn .BodySection .reportButtonSection {
    margin-right: 0px;
    margin-left: 150px;
}

.HistoricalReturn .BodySection .bottomTextSection {
    margin: auto;
    text-align: left;
    margin-bottom: 120px;
}

.temp {
    margin-top: 20px;
    margin-bottom: 100px;
}
.HistoricalReturn table {
    width: 100%;
}
.HistoricalReturn thead {
    font-weight: bold;
}
.HistoricalReturn tr {
    height: 30px;
}
tr:nth-child(even) {
    background-color: #f2f2f2;
}
.responsive-table {
    overflow-x: auto;
    width: 100%;

    overflow: scroll;
}

@media screen and (min-width: 1800px) {
    .HistoricalReturn .BodySection .FirstSectionInnerContnet {
        max-width: 1200px;
    }
}
@media screen and (max-width: 800px) {
    .HistoricalReturn .marginOnMobile {
        padding: 0px !important;
        margin: 0px auto;
        width: 100%;
    }
}
