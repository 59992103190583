.InvestorNewsAndEvents .Header {
    height: 324px;
    background-image: url(./background.png);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.InvestorNewsAndEvents .Header .plainText {
    max-width: 646px;
    margin: 0px auto;
}

@media screen and (max-width: 800px) {
    .InvestorNewsAndEvents .Header {
        height: 120px;
    }
}
