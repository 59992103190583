.InvestorWhyUs .Header {
    min-height: 324px;
    background-image: url(./background.png);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.InvestorWhyUs .Header .plainText {
    max-width: 500px;
    margin: 0px auto;
}

@media screen and (max-width: 800px) {
    .InvestorWhyUs .Header {
        height: 220px;
        min-height: 150px;
        padding: 20px 0% 0px 0%;
    }
    .InvestorWhyUs .Header .marginOnMobile {
        padding: 0px !important;
        margin: 0px auto;
        width: 90%;
    }
}
