.OtherPosts {

}

.OtherPosts .owl-carousel {
    padding-bottom: 100px;
}

.OtherPosts .Carusel .arrowRight,
.OtherPosts .Carusel .arrowLeft {
    bottom: 50px;
    top: unset;
} 

.OtherPosts .Carusel .arrowRight {
    right: -100px;
}

.OtherPosts .Carusel .arrowLeft {
    left: -100px;
}

.OtherPosts .Carusel .owl-theme .owl-nav.disabled + .owl-dots,
.OtherPosts .Carusel .owl-theme .owl-dots {
    display: none;
}