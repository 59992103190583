.Products .SpecialPromotion .tableWrap {
    position: relative;
}

.Products .SpecialPromotion table {
    width: 100%;
    position: relative;
    border-collapse: collapse;
}

.Products .SpecialPromotion .thead div {
    width: 150px;
    margin-right: 2px;
    cursor: pointer;
}

.Products .SpecialPromotion .underLine {
    height: 10px;
    position: absolute;
    right: 2px;
    left: 0px;
    top: 110px;
    z-index: 1;
}

.Products .SpecialPromotion table td {
    padding: 15px 10px;
    height: 32px;
    border: 0;
    border-width: 0px;
}

.Products .SpecialPromotion table td:nth-of-type(1){
    width: 20%;
}
.Products .SpecialPromotion table td:nth-of-type(2){
    width: 17%;
}
.Products .SpecialPromotion table td:nth-of-type(3){
    width: 20%;
}
.Products .SpecialPromotion table td:nth-of-type(4){
    width: 30%;
}
.Products .SpecialPromotion table td:nth-of-type(5){
    width: 13%;
}

.Products .SpecialPromotion .thead {
    padding-bottom: 10px;
    height: 70px;
    display: flex;
}

.Products .SpecialPromotion .condoRescue {
    display: flex;
    padding-left: 2px;
}

.Products .SpecialPromotion .condoRescue .condoRescue-image{
    flex: 0 1 50%;
}

.Products .SpecialPromotion .condoRescue .condoRescue-text{
    flex: 0 1 50%;
}

@media screen and (max-width: 991px) { 
    .Products .SpecialPromotion .condoRescue {
        flex-direction: column;
    }
}

.Products .SpecialPromotion img{
  display: block;
  height: auto;
  max-width: 100%;
}

.Products .SpecialPromotion .condoRescue ul li{
    font-size: 16px;
}

@media screen and (max-width: 800px) { 
    .Products .SpecialPromotion {
        padding-right: 0px;
    }

    .Products .SpecialPromotion table td {
        padding: 10px;
    }

    .Products .SpecialPromotion .underLine {
        top: 90px;
    }

    .Products .SpecialPromotion .thead {
        height: 60px;
        min-width: 552px;
    }
    
    .Products .SpecialPromotion .tableWrap {
        overflow-x: scroll;
    }

    .Products .SpecialPromotion .tableWrap table {
        min-width: 550px;
    }

    .Products .SpecialPromotion .underLine {
        right: unset;
        left: 0px;
        min-width: 550px;
        top: 90px;
    }
}