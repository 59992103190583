.BulletCard {
    height: 48px;
}

.CheckIconImage {
    width: 24px;
    height: 24px;
    vertical-align: middle;
}
@media screen and (max-width: 800px) {
}
