.ContactForm {
    min-height: 500px;
 /*    background-image: url(./background.png); */
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: 275px;
}

.ContactForm .ContactFormRow {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
}

.ContactForm .ContactFormRow .TextField {
    width: calc(50% - 30px);
}

.ContactForm .ContactFormTextarea {
    padding: 20px 0px 40px;
}

@media screen and (max-width: 800px) {
    .ContactForm {
        min-height: 200px;
        padding-bottom: 190px !important;
        background-size: 175px;
        background-position: -20px calc(100% + 50px);
    }
    
    .ContactForm .ContactFormRow {
        display: initial;
        padding-bottom: 0px;
    }

    .ContactForm .ContactFormRow .TextField{
        width: 100%;
        margin-bottom: 20px;
    }

    .ContactForm .ContactFormTextarea {
        padding: 20px 0px 40px;
    }
}