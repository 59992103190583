.AnalystRating .RatingGraphSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.LoanPortfolio .RatingGraphSection .reportButtonSection {
    margin-right: 0px;
    margin-left: 150px;
}

.LoanPortfolio .RatingGraphSection .bottomTextSection {
    margin: auto;
    text-align: left;
}
.LoadPortfolioTableDiv {
    overflow: scroll;
    height: 500px;
}
.LoadPortfolioTable {
    width: 100%;
}
.LoanPortfolio thead {
    font-weight: bold;
}
.LoanPortfolio tr {
    height: 30px;
}
.LoanPortfolio tr:nth-child(even) {
    background-color: #f2f2f2;
}

@media screen and (max-width: 800px) {
    .LoanPortfolio .MuiGrid-grid-xs-4,
    .LoanPortfolio .MuiGrid-grid-xs-6,
    .LoanPortfolio .MuiGrid-grid-xs-12 {
        max-width: 100% !important;
        flex-basis: 100%;
        width: 100%;
        padding-left: 0px;
    }

    .LoanPortfolio .marginOnMobile {
        padding: 0px !important;
        margin: 0px;
        width: 100%;
    }
}
