.TimelineCard {
    height: 170px;
}
.InvestorOurStory .NumberBullet {
    border-radius: 25px;
    border: 1px solid #b0d585;
    width: 50px;
    height: 50px;
    line-height: 50px;
}

.InvestorOurStory .NumberBulletDiv {
    width: 50px;
}
.VerticalLine {
    width: 1px;
    height: 120px;
    margin: 0px auto;
}
@media screen and (max-width: 800px) {
}
