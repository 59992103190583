.InvestorGallery .BodySection .SingleCard {
    height: auto;
}

.InvestorGallery .BodySection .SingleCard .smallImage {
    height: 359px;
    width: 375px;
    padding-bottom: 5px;
}

.InvestorGallery .BodySection .SingleCard .bigImage {
    height: 726px;
    width: 375px;
}
@media screen and (min-width: 1500px) {
    .InvestorGallery .BodySection .SingleCard .smallImage {
        width: 100%;
        max-width: 100%;
        margin-right: 40px;
    }

    .InvestorGallery .BodySection .SingleCard .bigImage {
        width: 100%;
        max-width: 100%;
        margin-right: 40px;
    }
}
@media screen and (max-width: 1200px) {
    .InvestorGallery .BodySection .SingleCard .smallImage {
        width: 100%;
        max-width: 100%;
        height: 450px;
        margin-right: 40px;
    }
    .InvestorGallery .BodySection .SingleCard .bigImage {
        width: 100%;
        max-width: 100%;
        margin-right: 40px;
    }
}
@media screen and (max-width: 800px) {
    .InvestorGallery .BodySection .SingleCard .smallImage {
        width: 100%;
        max-width: 100%;
        margin-right: 40px;
        width: 359x;
    }
    .InvestorGallery .BodySection .SingleCard .NoPaddingRightOnMobile {
        padding-right: 0px;
    }
}
