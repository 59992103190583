.AboutUs .Header {
    min-height: 400px;
    background-image: url(./background.png);
    padding: 300px 10% 0px 50%;
    
}

@media screen and (max-width: 800px) { 
    .AboutUs .Header {
        min-height: 150px;
        padding: 20px 0% 0px 0%;
    }
    
}