.InvestorTeam .BodySection {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.InvestorTeam .BodySection .FirstSectionInnerContnet {
    max-width: 646px;
    margin: 0px auto;
}
.InvestorTeam .BodySection .mainImage {
    width: 460px;
    height: 460px;
    object-fit: contain;
}
.InvestorTeam .BodySection .imageButton {
    background: none;
    border: none;
    object-fit: scale-down;
}

.InvestorTeam .BodySection .contentDiv {
    width: 90%;
    max-width: 520px;
}
.InvestorTeam .BodySection .titleSection {
    height: 50px;
}
.InvestorTeam .BodySection .textSection {
    height: 260px;
}

@media screen and (min-width: 2100px) {
    .InvestorTeam .BodySection .contentDiv {
        max-width: 1000px;
    }
}

@media screen and (min-width: 2350px) {
    .InvestorTeam .BodySection .contentDiv {
        max-width: 1200px;
    }
}
@media screen and (max-width: 1200px) {
    .InvestorTeam .pagePadding-Investor {
        padding-left: 0px;
        padding-right: 0px;
    }
    .InvestorTeam .imageButton img {
        width: 100px;
        height: 100px;
    }
}
@media screen and (max-width: 800px) {
    .InvestorTeam .BodySection .mainImage {
        width: 200px;
        height: 200px;
        object-fit: contain;
        margin-left: auto;
        margin-right: auto;
    }
    .InvestorTeam .NoPaddingOnMobile {
        max-width: 100% !important;
        flex-basis: 100%;
        width: 100%;
        padding-left: 0px;
    }

    .InvestorTeam .MuiGrid-grid-xs-2 {
        max-width: 30% !important;
        flex-basis: 30%;
        width: 30%;
        padding-left: 0px;
    }

    .InvestorTeam .imageButton img {
        width: 80px;
        height: 80px;
    }

    .InvestorTeam .BodySection .textSection {
        height: auto;
        margin-bottom: 20px;
    }

    .InvestorTeam .BodySection .contentDiv div img {
        width: 40px;
        height: 40px;
        padding-right: 20px;
    }

    .InvestorTeam .pagePadding-Investor {
        padding-left: 30px;
        padding-right: 30px;
    }
}
