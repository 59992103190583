.MapSection .SingleCard {
    height :150px;
}

.MapSection .SingleCard img {
    object-fit: cover;
    width:78px;
    height:78px;
    margin :0px auto;
}
.MapSection .SingleCard .textWrap .innerContent{
    padding-left:0px;
    padding-top:0px;
    margin-top:10px;
}
.MapSection .SingleCard .textWrap .H2{
    height:30px;
}

.MapSection .SingleCard .textWrap .bodyText{
    height:21px;
}
@media screen and (max-width: 800px) { 
    .SingleCard .textWrap {
        min-height: 267px;
    }
}