.HorizontalTextField {
    flex-grow: 1;
    height: 35px;
    flex: 1;
}

.HorizontalTextField .label  {
    min-width: 120px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.HorizontalTextField input {
    flex-grow: 1;
    height: 100%;
    border: none;
    padding: 0px 10px;
    min-width: 400px;
}

@media screen and (max-width: 800px) { 

    .HorizontalTextField {
        padding-top: 15px;
        height: 40px;
    }

    .HorizontalTextField .label  {
        padding-bottom: 5px;
        justify-content: flex-start;
    }
}