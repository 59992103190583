.InvestorOurStory .Header {
    height: 324px;
    background-image: url(./background.png);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.InvestorOurStory .Header .plainText {
    max-width: 646px;
    margin: 0px auto;
}
@media screen and (max-width: 800px) {
    .InvestorOurStory .Header {
        min-height: 150px;
        padding: 20px 0% 0px 0%;
        height: 200px;
    }
    .InvestorOurStory .Header .marginOnMobile {
        padding: 0px !important;
        margin: 0px auto;
        width: 90%;
    }
}
