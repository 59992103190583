.Appraisers .Header {
    height: 240px;
    background-image: url(./background.png);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Appraisers .Header .plainText {
    max-width: 500px;
    margin: 0px auto;
}

@media screen and (max-width: 800px) { 
    .Appraisers .Header {
        height: 120px;
    }
}