.SingleListItem {
    border-bottom: 2px solid;
}

.SingleListItem.noBorder {
    border: none;
}

.SingleListItem .icon {
    border-radius: 50%;
    height: 45px;
    min-width: 45px;
    background-image: url(./checked.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 60%;
}

@media screen and (max-width: 800px) { 
    .SingleListItem .titleWrap {
        display: flex;
        align-items: center;
    }

    .SingleListItem .icon  {
        margin-right: 5px;
        height: 20px;
        min-width: 20px;
        background-size: 10px;
    }

}