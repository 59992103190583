.PostBody {

}

.PostBody .rowItem {
    flex: 1;
}

.PostBody .rowItem img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}