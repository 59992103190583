.InvestorGallery .blueLine {
    height: 4px;
    width: 100%;
}
@media screen and (max-width: 1500px) {
    .InvestorGallery .pagePadding-Investor {
        padding-left: 60px;
        padding-right: 60px;
    }
}

@media screen and (max-width: 1300px) {
    .InvestorGallery .pagePadding-Investor {
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media screen and (max-width: 1200px) {
    .InvestorGallery .MuiGrid-grid-xs-4 {
        max-width: 100% !important;
        flex-basis: 100%;
        width: 100%;
        padding-left: 0px;
    }
}
