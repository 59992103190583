.PrimaryNavigation {
    position: relative;
    z-index: 3;
}

.PrimaryNavigation .topNav {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 54px;
}

.PrimaryNavigation .userTypeWrap {
    transform: translateY(5px);
}

.PrimaryNavigation .signinType {
    display: flex;
    align-items: center;
    width: 262px;
    height: auto;
}

.PrimaryNavigation .signinType label {
    color: #000;
    font-size: 14px;
}

.PrimaryNavigation .topNav .topNavItem {
    padding: 15px 40px 14px;
    cursor: pointer;
    margin-top: 10px;
    margin-right: 10px;
}

.PrimaryNavigation .topNav .brokerTabWrap {
    transform: translateY(-5px);
    color: #f7921e;
    font-size: large;
    font-weight: bold;
}

.PrimaryNavigation .topNav .rightSide .iconRow {
    align-items: center;
    padding: 0px 10px;
}

.PrimaryNavigation .topNav .rightSide .iconRow img {
    margin-right: 10px;
    height: 20px;
}

.PrimaryNavigation .topNav .rightSide .iconRow:last-of-type img {
    height: 17px;
}

.PrimaryNavigation .topNav .rightSide .SocialIcons {
    margin: 0px 20px;
}

.PrimaryNavigation .navigationContent {
    display: flex;
    justify-content: space-between;
/*     padding-top: 10px;
    padding-bottom: 10px;
  */   align-items: center;
    border-bottom: 1px solid #c5d4db;
}

.PrimaryNavigation .mobileNavigationContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.PrimaryNavigation .navigationContent .logo {
    max-height: 45px;
    margin-right: 30px;
}

.PrimaryNavigation .navigationContent .menuItem {
    padding: 31px 15px;
    margin: -11px 0px;
    font-weight: 500;
}

.PrimaryNavigation .navigationContent .menuItem.active {
    text-shadow: 0px 0px 0.75px;
}

.PrimaryNavigation .dropdown {
    cursor: pointer;
    position: relative;
    opacity: 1 !important;
    padding: 12px 0 !important;
}

.PrimaryNavigation .dropdown .target {
    display: flex;
    align-items: center;
    justify-content: center;
}

.PrimaryNavigation .dropdown .target .arrow {
    margin-left: 5px;
}

.PrimaryNavigation .dropdown .dropdownItems {
    position: absolute;
    top: calc(100% - 1px);
    display: flex;
    flex-direction: column;
    min-width: 150px;
    opacity: 0;
    pointer-events: none;
    z-index: 2;
}

.PrimaryNavigation .dropdown:hover .dropdownItems {
    opacity: 1;
    pointer-events: all;
}

.PrimaryNavigation .dropdown .dropdownItems .ddItem {
    padding: 5px 10px;
    border-right: 1px solid #c5d4db;
    border-left: 1px solid #c5d4db;
    opacity: 0.75;
}

.PrimaryNavigation .dropdown .dropdownItems .ddItem.active {
    opacity: 1;
    text-shadow: 0px 0px 0.5px;
}

.PrimaryNavigation .dropdown .dropdownItems .ddItem:last-of-type {
    border-bottom: 1px solid #c5d4db;
}

.PrimaryNavigation .menuLogoRowWrap .iconWrap {
    display: none;
}

.PrimaryNavigation .dropdown .arrow {
    width: 0;
    height: 0;
    border-left: 7.5px solid transparent;
    border-right: 7.5px solid transparent;
    border-top: 7.5px solid;
}

.PrimaryNavigation .dropdown:hover .arrow {
    transform: rotate(-180deg);
}

@media screen and (max-width: 800px) {
    .PrimaryNavigation {
        display: flex;
        flex-direction: column-reverse;
        border-bottom: 1px solid #c5d4db;
    }

    .PrimaryNavigation.open {
        padding-bottom: 30px;
    }

    .PrimaryNavigation .menuLogoRowWrap {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .PrimaryNavigation .menuItem.dropdown {
        padding-right: 0 !important;
        padding-left: 0 !important;
        text-align: center;
    }

    .PrimaryNavigation .navigationContent .menuItem {
        padding: 15px;
        margin: 0px;
    }

    .PrimaryNavigation .dropdown .dropdownItems {
        position: initial;
        height: 0;
        opacity: 0;
        pointer-events: none;
    }

    .PrimaryNavigation .dropdown:hover .dropdownItems {
        position: initial;
        height: auto;
        padding-top: 20px;
        opacity: 1;
        pointer-events: all;
    }

    .PrimaryNavigation .menuLogoRowWrap .iconWrap {
        display: inline-flex;
    }

    .PrimaryNavigation .navigationContent .logo {
        max-height: 35px;
        margin-right: 0px;
    }

    .PrimaryNavigation .topNav {
        background-color: #fff;
        height: auto;
    }

    .PrimaryNavigation .topNav .topNavItem {
        display: block;
        margin-top: 0;
    }

    .PrimaryNavigation .topNav,
    .PrimaryNavigation .mobileNavigationContent,
    .PrimaryNavigation .navigationContent {
        flex-direction: column;
    }

    .PrimaryNavigation .navigationContent {
        border: none;
    }

    .PrimaryNavigation .lightText {
        color: inherit;
    }

    /*.PrimaryNavigation .topNav .rightSide {
        width: calc(100% - 40px);
    }*/

    .PrimaryNavigation .topNav .rightSide .iconRow {
        flex-direction: row;
        padding: 5px 0px;
    }

    .PrimaryNavigation .topNav .rightSide .iconRow .iconWrap {
        min-width: 40px;
    }

    .PrimaryNavigation .userTypeWrap {
        display: flex;
        transform: none;
    }

    .PrimaryNavigation .userTypeWrap .lightBackground rb {
        border-bottom: 1px solid;
    }

    .PrimaryNavigation .topNav .rightSide .SocialIcons {
        margin: 20px auto;
    }

    .PrimaryNavigation .dropdown .target {
        padding: 0;
    }
}
